import React from 'react';
import SkillCard from '../components/SkillCard';

const skillSets = [
  {
    category: 'Educational Technology',
    skills: [
      'Learning Management Systems (LMS)',
      'Digital Learning Tools',
      'Remote Learning Solutions',
      'Educational Content Development',
      'Student Progress Tracking',
      'Interactive Learning Resources',
      'Educational Analytics',
      'Curriculum Integration'
    ],
  },
  {
    category: 'Technical Skills',
    skills: [
      'React / React Native',
      'Node.js / Express',
      'Firebase',
      'JavaScript / HTML / CSS',
      'C++ / C#',
      'PostgreSQL / MongoDB / SQL',
      'API Development'
    ],
  },
  {
    category: 'Product & Project Management',
    skills: [
      'Feature Prioritization & Roadmapping',
      'Agile & Scrum Methodologies',
      'Technical Requirement Gathering',
      'Stakeholder Collaboration',
      'User Feedback Integration',
      'Data-Driven Decision Making'
    ],
  },
  {
    category: 'Professional Skills',
    skills: [
      'Strategic Communications',
      'Technical Writing',
      'Data Analysis',
      'User Training',
    ],
  }
];

function Skills() {
  return (
    <div className="skills">
      <h2>My Skills</h2>
      <div className="skills-grid">
        {skillSets.map((skillSet, index) => (
          <SkillCard key={index} {...skillSet} />
        ))}
      </div>
    </div>
  );
}

export default Skills;