import React from 'react';
import { Link } from 'react-router-dom';

function Home() {
  return (
    <div className="home">
      <div className="animated-bg">
        <div className="shape circle1"></div>
        <div className="shape circle2"></div>
        <div className="shape triangle1"></div>
        <div className="shape triangle2"></div>
        <div className="shape square1"></div>
        <div className="shape square2"></div>
      </div>
      <h2>Welcome to My EdTech Portfolio</h2>
      <p>Dedicated English educator with expertise in language instruction and learning technology. Creates dynamic learning environments through curriculum innovation and personalized approaches. Passionate about enhancing language acquisition and student growth with evidence-based teaching methods.</p>
      <div className="cta-buttons">
        <Link to="/projects" className="cta-button">View My Projects</Link>
        <Link to="/skills" className="cta-button">See My Skills</Link>
      </div>
    </div>
  );
}

export default Home;